/* this file will be extracted to main dist folder and is imported in index.html */
/* This file is for setting global styles  */
$font-family-sans-serif:  'Catamaran', sans-serif;
@import '../../node_modules/bootstrap/scss/bootstrap.scss';
@import '../../node_modules/animate.css/animate.min.css';
@import '../../node_modules/c3/c3.min.css';

@import 'variables';
$font-family-sans-serif:  'Catamaran', sans-serif;

html, body{
  height: 100%;
  background: $bg-main;
  font-size: 0.92rem;
}
html, body, .main {
  background: $bg-main;
}
.main {
  padding:30px !important;
}
@media (min-width: 1px) and (max-width: 480px) {
  .main {
    padding:5% !important;
    width: 100%;
    overflow-x: auto;
  }
  body {
    padding-top: 60px !important;
  }
  .btn-group {
    width: 100%!important;
  }

  select {
    width: 100%!important;
    overflow: hidden;
  }
  label {
    text-align: left!important;
  }

  [ngbradiogroup].btn-group {
    flex-wrap: wrap;
  }

  .overflow-x-scroll {
    overflow-x: scroll;
  }
  .dash-box {
    height:80px !important;
    padding:6px 10px !important;
  }
  .dash-box > p {
    margin-bottom: 0px !important;
    padding-top: 20px !important;
    width: 70% !important;
    clear: none !important;
    text-align:left !important;
    float: left !important;
    img {
      max-width:55px !important;
    }

  }
  .dash-box > p.ic {
    width: 25% !important;
    padding-top: 0px !important;
}



}
// IMPORTANTE: questo risolve il problema delle tabelle tagliate oltre i 480 px
// I 992px di fine sono stati presi perchè rappresentano il break point empirico per la visualizzazione simil tablet
// Ho scelto di non applicarlo sempre perchè non è mai stato richiesto lo scroll su desktop, è dunque giusto che
// un eventuale errore di layout su desktop sia immediatamente evidente e corretto
@media (min-width: 481px) and (max-width: 992px) {
  .overflow-x-scroll {
    overflow-x: auto;
  }
}

a.active {
  background-color: gray;
}
div.mceu_31 {
  display:none;
}
body {
  padding-top: 51px;
}
button, .btn-primary, .btn-info, .btn-dark, .pointer {
  cursor:pointer;
}
.btn-group .btn-primary:not(.active),
.btn-group .btn-info:not(.active),
.btn-group .btn-success:not(.active),
.btn-group .btn-warning:not(.active),
.btn-group .btn-danger:not(.active)
{
  background:#eee;
  border-color: #ababab;
  color:#333;
}

/*
 * Typography
 */
 label.btn {
   margin:0;
 }
button:focus, button:active, button.active, button.focus {outline:0 !important; box-shadow:none !important;}
h1 {
  padding-bottom: 9px;
  margin-bottom: 20px;
  font-size:1.2rem;
  border-bottom: 1px solid rgba(0,0,0,.0625);
}
a{
  color: $link-main;
}

.btn-inactive {
  background:#777 !important;
  border-color:#777 !important;
  box-shadow: none !important;
  color:#fff !important;
}
.bg-dark {
  color:#fff;
}

.block {
  border: 1px solid $border-main;
  border-radius:4px;
  padding:15px;
  background: $bg-contrast;
  margin-bottom:30px;
}
.block-m-t {
}

tr.resolved, tr.resolved td {
  color: $success;
}
td.bg-option, th.bg-option {
  background:#f3f3f3;
  padding-left:0;
  padding-right:0;
  border:0;
}
tr.refused, tr.refused td {
  color: $danger;
  text-decoration: line-through;
}
.btn-edit {
   min-width:80px;
}
.blockquote-footer, .text-muted {
  color: #667482;
}

.bg-lead, td.bg-lead {
  padding:$spacer;
  border:1px solid $border-lead;
  border-radius: $radius-lead;

  color:$color-lead;
  margin:0;
  margin-bottom: $spacer;
  background: $bg-lead;
  font-size:18px;
  font-weight:bold;
}
td.bg-lead {
  margin:0;
  border:0;
}
.bg-lead > * {
  margin:0;
  font-weight:bold;
  padding:0;
  font-size:18px;
}

td.td-buttons {
  padding-bottom:0;
  padding-top:$spacer/2;
}

.nav-tabs .nav-link {
  color: $color-lead-2;
  font-size:18px;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link.active {
  font-size:18px;
  color:$color-lead;
  background: $bg-lead;
  border-color: $border-lead;
}

.tabs {
  border-bottom:1px solid $bg-lead;

}
.tabs button.btn-info {
  color:$color-lead;
  background: $bg-lead;
  border-color: $border-lead;
}
.tabs button.btn-link {
  color: #111;
}
.tabs button {
  border-radius:$border-radius $border-radius 0 0 ;
  border-bottom:0;
}
.mce-notification-inner {
  display:none !important;
}
/*
 * Sidebar
 */

.sidebar {
  position: fixed;
  top: 51px;
  bottom: 0;
  left: 0;
  z-index: 1000;
  padding: 20px 0;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
  border-right: 1px solid $border-main;
}
.width-auto{
  width: auto;
}
.sidebar, header nav {
  background:$bg-contrast;
  border-bottom: 1px solid $border-main;
}
.sidebar .nav {
  margin-bottom: 20px;
}

.sidebar .nav-item {
  width: 100%;
}

.sidebar .nav-item + .nav-item {
  margin-left: 0;
}

.sidebar .nav-link {
  border-radius: 0;
}
.sidebar .nav-link .fa {
  width:28px;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
   background: $info;
   color:#fff;
   border-top:1px solid $info;
   border-bottom:1px solid $info;
}
.nav-pills .nav-link, .nav-pills .show > .nav-link {
   color:#333;
}

/** NAVBAR START */
button:focus {
    outline: 0px dotted;
    outline: 0px auto -webkit-focus-ring-color;
}
.navbar-light {
  background:$border-main;
}
a.navbar-brand {
  font-size:0.9em;

}
a.navbar-brand img {
  max-height:40px;
}

/** NAVBAR END */


/** FORMS AND COMPONENTS START */

agm-map, .sebm-google-map-container {
  height: 400px;
}
.dash-box {
  width:100%;
  border:1px solid #ddd;
  border-radius:10px;
  background:#fff;
  box-shadow: 2px 2px 6px #aaa;
  margin-bottom:15px;
  padding:15px;
  height:180px;
  cursor:pointer;
  border-radius:4px;
  p {
    font-size:20px;
    color:#777;
    text-align:center;
    margin-bottom: 20px;
  }
  p.ic {
    margin-bottom: 10px;
    font-size:40px;
    img {
      max-width:96px;
      color:red !important;
    }
  }
}

.dash-box-1 {
  .fa { color:  #ABA414 }
}
.dash-box-1.dash-box-selected {
  background: #ABA414;
  p { color: #fff !important; }
}

.dash-box-2 {
  .fa { color:  #CD7B70 }
}
.dash-box-2.dash-box-selected {
  background: #CD7B70;
  p { color: #fff !important; }
}

.dash-box-3 {
  .fa { color:  #519EBC }
}
.dash-box-3.dash-box-selected {
  background: #519EBC;
  p { color: #fff !important; }
}
.dash-box-4.dash-box-selected {
  background: #9d9d9c;
  p { color: #fff !important; }
}
.dash-box-5.dash-box-selected {
  background: #68a3bf;
  p { color: #fff !important; }
}
.dash-box-6.dash-box-selected {
  background: #b5a800;
  p { color: #fff !important; }
}
.dash-box-7.dash-box-selected {
  background: #ce7365;
  p { color: #fff !important; }
}





label.btn input {
  visibility: hidden;
  display: none !important;
}
label {
  font-size:120%;
}
.tab-pane {
  padding-top:$spacer;
  padding-bottom:$spacer*5;
}
.form-control {
  border-color:#ababab;
}
div.btn-group > div > .btn-group {
  margin-bottom:0 !important;
}

// form.searchForm [class^='col-'] {
form.searchForm .btn-group {
  margin-bottom:$spacer;
  label.btn {
    min-width:50px;
    width:auto;
    cursor:default;
    display:inline;
    // text-align:left;

    box-sizing:initial;
  }
  label.btn-auto {
    min-width:auto;
  }
}
/** FORMS AND COMPONENTS END */

/** PAGINATION START */
.page-item.active .page-link {
  border-color: $info;
  background: $info;
}
.page-link {
  color: $info;
}
/** PAGINATION END */

/** NOTE START */

.note {
  padding:15px;
  border:4px solid $border-main;
  background:#efefef;
  border-radius:2px;
}
blockquote small {
  color:#000;
  font-size:82%;
}
blockquote.note-unread  {
  box-shadow:0 0 0 2px #d5b5b5;

}
p.read-button, small.read-label {
  display:none;
}
.note-unread p.read-button {
  display: block;
  margin-top:15px;margin-bottom:0;
}
.note-unread small.read-label {
  display: inline-block;
}
/** NOTE END */

/*LOGIN START */
.area-login main {
  margin-left:0 !important;
  width:100% !important;
  min-width:100% !important;
}

.form-login {
  background:#fff;
  padding:32px;
  border:1px solid $border-main;
  border-radius:8px;
}

/*LOGIN END */

/**LOADING BAR START*/
.ng-spinner {
  top:45vh !important;
  left:49%;
  background: rgba(0, 0, 0, 0.7);
  color:#fff;
  border-radius:5px;
  padding:10px;
  width:40px;
  height:40px;
}


[spinnerPosition=right] .ng-spinner {
    right: auto;
}


div.area-login .spinner[_ngcontent-c2] {
  display:none !important;
}
.core-loading-in {
  display:none;
  text-align:center;
  width:100%;
  padding:20px;
}
.core-loading * {
  display:none !important;
}
.core-loading .core-loading-in {
  display:block !important;
}
/**LOADING BAR END*/

/** CHART START */
.chart {
  display:block
}
.chart-heading, .title {
  color:#333;
  font-weight: bold;
  font-size:1.1rem;
  padding:6px 0;
  border-bottom: 1px solid $border-main;
  margin-bottom:12px;
}
.chart-heading-2 {
  margin-bottom:6px;
  padding-bottom:0;
  border-bottom:0;
}
.chart-left {
  width:65%;
  clear:none;
  float:left;
  display:inline-block;
}
.chart-right {
  width:30%;
  clear:none;
  display:inline-block;
  float:right;
}
.chart-right-2 {
  margin-top:16px;
}

.chart-heading .badge {
  font-size:1.1rem;
}
.chart-body {
  text-align:center;
  min-height:160px;
}
.chart-inline .chart-body {
  min-height:auto;
}
.chart .table td.td-number,
.chart .table td.td-currency {
  text-align:center;
  max-width: 30px;
  padding-left: 0;
  padding-right: 0;
}
.chart .table td.td-currency {

  max-width:90px;
}

*[class^='bg-'] .chart, *[class^='bg-'] .chart-heading, *[class^='bg-'] .title {
  color:#fff !important;
}
.bg-info .chart-heading,
.bg-info .chart,
.bg-primary .chart-heading,
.bg-primary .chart,
.bg-success .chart-heading,
.bg-success .chart,
.bg-warning .chart-heading,
.bg-warning .chart,
.bg-danger .chart-heading,
.bg-danger .chart,
.bg-dark .chart-heading,
.bg-dark .charts
 {
  color:#fff;
}
/** CHART END */

/** SPECIAL START */
@keyframes highlight {
  0% {
    background: none
  }
  25% {
    background: $warning;
    color:#7a7a7a;
  }
  100% {
    background: none;
    color:inherit;
  }
}

.highlight, tr.highlight, td.highlight, div.highlight {
  animation: highlight 1s;
}
.block-inline {
  padding: $spacer;
  background: $dark;
}

.block-inline .block {
  margin-bottom:0 !important;
  background: #fff;
  min-height:200px;
}
.btn-block-inline-active {
  border-radius:0 !important;;
  color: #fff;
  background: $dark;
  border-color: $dark;
}
/** SPECIAL END */



@media print {
 /* All your print styles go here */
 .nav-dark,.container-fluid .sidebar, h5, .form-group, button {
   display:none !important;
 }
 .container-fluid main {
   width:100% !important;
   font-size:14px !important;
   max-width:100% !important;
   min-width:100% !important;
   margin:0 !important;
 }
 .ticket-edit .col-lg-4 {
   width: 33%!important;
 }
}
.datepicker-only-month-select {
  border: 0 !important;
  .ngb-dp-months {
    display: none !important;
  }
}

.calendar {
  background-position: center center;
background-repeat: no-repeat;
background-size: 1rem;
height:1rem;
width: 1rem;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMzQgMjgiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDM0IDI4IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxnPg0KCQk8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZmlsbD0iIzQ0NDQ0NCIgZD0iTTMxLDBIM0MxLjMsMCwwLDEuMywwLDN2MjJjMCwxLjcsMS4zLDMsMywzaDI4YzEuNywwLDMtMS4zLDMtMyAgICBWM0MzNCwxLjMsMzIuNywwLDMxLDB6IE0zMiwyNWMwLDAuNi0wLjQsMS0xLDFIM2MtMC42LDAtMS0wLjQtMS0xVjhoMzBWMjV6IE0zMiw2SDJWM2MwLTAuNiwwLjQtMSwxLTFoMjhjMC42LDAsMSwwLjQsMSwxVjZ6ICAgICBNOCwxNmMxLjEsMCwyLTAuOSwyLTJzLTAuOS0yLTItMmMtMS4xLDAtMiwwLjktMiwyUzYuOSwxNiw4LDE2eiBNMTQsMTZjMS4xLDAsMi0wLjksMi0ycy0wLjktMi0yLTJjLTEuMSwwLTIsMC45LTIsMiAgICBTMTIuOSwxNiwxNCwxNnogTTIwLDE2YzEuMSwwLDItMC45LDItMnMtMC45LTItMi0yYy0xLjEsMC0yLDAuOS0yLDJTMTguOSwxNiwyMCwxNnogTTI2LDE2YzEuMSwwLDItMC45LDItMnMtMC45LTItMi0yICAgIGMtMS4xLDAtMiwwLjktMiwyUzI0LjksMTYsMjYsMTZ6IE04LDIyYzEuMSwwLDItMC45LDItMmMwLTEuMS0wLjktMi0yLTJjLTEuMSwwLTIsMC45LTIsMkM2LDIxLjEsNi45LDIyLDgsMjJ6IE0xNCwyMiAgICBjMS4xLDAsMi0wLjksMi0yYzAtMS4xLTAuOS0yLTItMmMtMS4xLDAtMiwwLjktMiwyQzEyLDIxLjEsMTIuOSwyMiwxNCwyMnogTTIwLDIyYzEuMSwwLDItMC45LDItMmMwLTEuMS0wLjktMi0yLTIgICAgYy0xLjEsMC0yLDAuOS0yLDJDMTgsMjEuMSwxOC45LDIyLDIwLDIyeiBNMjYsMjJjMS4xLDAsMi0wLjksMi0yYzAtMS4xLTAuOS0yLTItMmMtMS4xLDAtMiwwLjktMiwyQzI0LDIxLjEsMjQuOSwyMiwyNiwyMnoiLz4NCgk8L2c+DQo8L2c+DQo8L3N2Zz4=')
}

.display-inline-block {
  display: inline-block;
}

button.datepicker-clearform {
  position: absolute;
  bottom: 6px;
  right: 40px;
  border: none;
  background-color: inherit;
}

.week-picker div.btn-light.bg-primary.text-white:after {
  background-color: grey;
  height: 2rem;
  content: "";
  width: 100%;
  position: absolute;
  left: 0;
  opacity: 0.2;
}

.week-picker div.ngb-dp-day:not(.disabled):not(.hidden):hover {
  background-color: grey;
  color: black !important;
  height: 2rem;
  width: 209px;
  position: relative;
  opacity: 0.2;
  margin-right: -180px;
}

.week-picker div.ngb-dp-day:not(.disabled) .btn-light:hover {
  color: white !important;
  background-color: #007bff !important;
}

.c3-region.regModeOff {
  fill: gray;
}

.c3-region.regModeHot {
  fill: red;
}

.c3-region.regModeCold {
  fill: blue;
}

.c3-chart-line.c3-target-dashed {
  stroke-dasharray: 5,5;
}

.dunut-chart .c3-text.c3-empty {
  transform: translateY(20px);
}

.wrap {
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

td.mobile-col-user {
  max-width: 30px;
  font-size: 11px;
}
th.mobile-col-user {
  max-width: 35px;
  padding-left: 2px;
  padding-right: 2px;
}

.form-standard .ng-valid[required], .ng-valid.required {
  border-left: 5px solid #42A948; /* green */
}

.form-standard ng.touched.ng-invalid:not(form) {
  border-left: 5px solid #a94442; /* red */
}

textarea {
  min-height: 150px;
}

