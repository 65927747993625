$nav-button-color: #002830;

$bg-main: #DCDDDF;
$bg-lead: $dark;
$border-lead: #eee;
$radius-lead: 6px 6px 0 0;
$color-lead: #fff;
$color-lead-2: #555;
$bg-contrast: #fff;
$border-main: rgba(0,0,0,.0625);

$link-main: $info;
